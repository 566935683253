import { Flex, Spinner } from '@chakra-ui/react';

const LoadingScreen = () => (
  <Flex
    align="center"
    justify="center"
    bg="brand.plumNew"
    height="100vh"
    width="100vw"
  >
    <Spinner size="xl" color="brand.hotPink" thickness="4px" />
  </Flex>
);

export default LoadingScreen;
