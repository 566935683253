import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import { UserProvider } from '@auth0/nextjs-auth0';
import customTheme from '../styles/theme';
import Auth from '../components/Auth';
import init from '../utils/sentry';
init();

function MyApp({ Component, pageProps }) {
  const { user } = pageProps;
  const router = useRouter();

  if (router.pathname === '/') {
    return (
      <ChakraProvider theme={customTheme}>
        <Component {...pageProps} />
      </ChakraProvider>
    );
  }

  return (
    <UserProvider user={user}>
      <ChakraProvider theme={customTheme}>
        <Auth>
          <Component {...pageProps} />
        </Auth>
      </ChakraProvider>
    </UserProvider>
  );
}

export default MyApp;
