import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0';
import LoadingScreen from '../UI/LoadingScreen';

const Auth = ({ children }) => {
  const { user, isLoading } = useUser();
  const router = useRouter();

  if (!user && !isLoading) router.push('api/auth/login');

  if (isLoading || !user) return <LoadingScreen />;
  return children;
};

export default Auth;
