import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  styles: {
    global: {
      html: {
        minHeight: '-webkit-fill-available',
        height: '100%',
      },
      body: {
        height: '100%',
      },
      '#__next': {
        height: '100%',
      },
      '.linkified': {
        color: '#0083ff',
      },
    },
  },
  colors: {
    brand: {
      plum: {
        50: '#ffe2f9',
        100: '#ffb1e1',
        200: '#ff7fcb',
        300: '#ff4db6',
        400: '#fe1ea2',
        500: '#e50788',
        600: '#b6006a',
        700: '#81004c',
        800: '#4f002e',
        900: '#1f0011',
      }, // primary colour
      // complementary brand colours
      teal: {
        50: '#e0f1ff',
        100: '#bedaf1',
        200: '#9ac4e4',
        300: '#74b0d7',
        400: '#4f9fcb',
        500: '#2D7d9E',
        600: '#286e8b',
        700: '#184a64',
        800: '#07283f',
        900: '#000d1a',
      },
      hotPink: '#C5006D',
      orange: '#DD6B20',
      green: '#38A169',
      plumNew: '#2D3748',
      yellow: '#d8de26',
      grey: '#EDF2F7',
    },
  },
  fonts: {
    body: '"Open Sans", sans-serif',
    heading: '"Open Sans", sans-serif',
  },
  textStyles: {
    label: {
      fontWeight: 'bold',
      display: 'inline-block',
      textAlign: 'right',
      marginRight: '2',
    },
  },
});

export default customTheme;
